.footer {
  background: black;
}
.footer-content {
  color: white;
  padding: 5rem 0;
  text-align: center;

  section {
    padding: 0;
    margin-bottom: 5rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h2 {
    color: white;
    font-size: 3rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
    padding: 0;
  }

  p,
  span,
  a {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  a {
    color: white;
    text-decoration: none;


    &:hover {
      text-decoration: underline;
    }
  }

  i {
    margin-right: 3px;
  }
}

.footer-legal p {
  font-size: 1.6rem;
  line-height: 2rem;
}

.footer-partners span {
  display: inline-block;
  margin-bottom: 1rem;
}

@media screen and (min-width: 1200px) {
  .footer-content {
    display: flex;
    flex-wrap: wrap;
    max-width: 1170px;
    margin: 0 auto;
    justify-content: space-between;
    text-align: left;
  }

  .footer-contact,
  .footer-legal {
    flex: 0 1 25%;
    margin: 0;
  }

  .footer-partners {
    flex: 1 0 100%;

    picture {
      display: block;
    }
  }
}
