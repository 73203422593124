section#Referenzen {
  background: #f7f7f7;
  padding-bottom: 0;
  h2 {
    margin-bottom: 40px;
  }
  h3 {
    color: $blue;
    font-family: 'Noto Sans', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 3.6rem;
  }
  h4 {
    font-family: 'Noto Sans', sans-serif;
    color: #666666;
    letter-spacing: 1px;
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 20px;
  }
  li {
    padding: 0 10px;
    .images {
      picture {
        display: block;
        position: relative;
        overflow: hidden;
        padding-top: 55%;
      }
      display: flex;
      flex-direction: row;
      margin-bottom: 20px;
      flex-wrap: wrap;
      & > a {
        flex: 1 1 33%;
        padding-right: 5px;
        &:nth-child(3n) {
          padding-right: 0;
        }
      }
      img {
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
  .glide__bullets {
    margin-top: 40px;
    padding: 0 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .glide__bullet {
      outline: 0;
      appearance: none;
      border: 0;
      cursor: pointer;
      width: 14px;
      height: 14px;
      background-color: black;
      margin-right: 10px;
      border-radius: 50%;
      margin-bottom: 20px;

      &.glide__bullet--active {
        background: $blue;
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  section#Referenzen {
    padding-left: calc((100vw - 1170px) / 2);
    padding-right: calc((100vw - 1170px) / 2);
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    li {
      .slider-part,.slider-double {
        // display: inline-block;
        // .images {
        //   flex-wrap: nowrap;
        //   // img {
        //   //   height: 134px;
        //   //   width: 173px;
        //   // }
        // }
        // &:nth-child(2n) {
        //   margin-left: 20px;
        // }
      }
    }
  }
}
