@import '../util.scss';
section#Jobs {
  padding: 20px;
  padding-top: 60px;
  h2 {
    margin-bottom: 40px;
  }
  .job-list {
    display: flex;
    flex-direction: column;
    .job {
      margin: auto;
      max-width: 400px;
      margin-bottom: 50px;
      border-radius: 5px;
      border: 1px solid lightgrey;
      padding-bottom: 20px;
      h3 {
        padding: 20px;
        font-size: 3.4rem;
        font-weight: 700;
      }
      h4 {
        font-family: 'Noto Sans', sans-serif;
        font-size: 1.8rem;
        margin-bottom: 20px;
      }
      .job-description {
        background: #f2f2f2;
        color: black;
        padding: 20px;
        font-family: 'Noto Sans', sans-serif;
        font-size: 2.6rem;
      }
      ul {
        padding-bottom: 20px;
      }
      li {
        font-size: 1.8rem;
        color: #333333;
        padding: 15px 0;
        margin: 0 30px;
        border-bottom: 1px dotted #d2d2d2;
      }
      a {
        text-decoration: none;
        display: inline-block;
        margin: 0 30px;
        width: 70%;
        padding: 10px 16px;
        background-color: #2e692e;
        border-radius: 5px;
        color: #fff;
        font-size: 18px;
        font-family: 'Noto Sans', sans-serif;
      }

      &:nth-child(2) {
        background-color: $blue;
        color: #fff;
        li {
          color: #fff;
        }
        a {
          color: #333;
          background: white;
        }
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  section#Jobs {
    .job-list {
      flex-direction: row;
      justify-content: space-around;
    }
  }
}
