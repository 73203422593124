@import '../util.scss';

section#intro {
  h1 {
    font-size: 3.6rem;
    line-height: 5rem;
    font-weight: 700;
    font-family: 'Noto Sans', sans-serif;
  }
  h2 {
    margin-bottom: 40px;
  }
  img.img-event-image {
    border-radius: 20px;
    margin-bottom: 10px;
  }
  & > p {
    margin-bottom: 60px;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  .overview-item {
    color: black;
    margin-bottom: 50px;
    padding-top: 0;
    img {
      width: 288px;
      height: 164px;
      object-fit: cover;
      object-position: center center;
      border-radius: 15px;
    }
    hr {
      width: 288px;
      border-color: #999;
      border: 0;
      border-top: 1px solid #999;
      margin: 0 auto;
    }
    h3 {
      color: #333333;
    }
    p {
      line-height: 1.6rem;
      font-size: 16px;
      padding: 0 20px 0 20px;
      font-family: 'Noto Sans', sans-serif;
      color: #555555;
    }
  }
}
@media screen and (min-width: 768px) {
  section#intro {
    .overview-item {
      display: flex;
      justify-content: space-around;
      picture {
        flex: 1 0 40%;
      }

      hr {
        display: none;
      }
    }
  }
}
@media screen and (min-width: 1200px) {
  section#intro {
    h1 {
      font-size: 60px;
      line-height: 72px;
    }
    .intro-overview {
      display: flex;

      .overview-item {
        display: initial;
      }
    }
  }
}
