@import '../util.scss';

section#Dachbeschichtung {
  padding-right: 20px;
  h2 {
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 15px;
  }
  & > h3 {
    padding-left: 20px;
  }
  & > div {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    & > picture {
      flex-basis: 33%;
      padding-right: 5px;
      height: 830px;
    }
    & > div {
      flex-basis: 66%;
      text-align: left;
    }
  }
  p,
  li {
    font-size: 1.4rem;
    line-height: 2.7rem;
    padding-bottom: 10px;
    text-align: left;
    font-family: 'Noto Sans', sans-serif;
  }
  & > ul {
    padding-left: 20px;
    & > li::before {
      font-family: 'FontAwesome';
      content: '\f058';
      padding-right: 10px;
    }
  }
}
@media screen and (min-width: 1200px) {
  section#Dachbeschichtung {
    & p,
    & li {
      font-size: 20px;
      line-height: 24px;
      padding-bottom: 20px;
    }
    h3 {
      margin-bottom: 30px;
    }
  }
}
