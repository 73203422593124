@import 'simplelightbox';

$blue: #3b74a5;
$bg-body: white;
$black-three: #333333;
$red: #d9534f;
$red-strong: #d2322d;
$grey-border: #d9d9d9;
$grey-cell-bg: #f2f2f2;
$grey-bg: #f7f7f7;
$green: #5cb85c;
$green-strong: #47a447;
$font-roboto-condensed: 'Roboto';
$font-arial: 'Arial';
$font-noto: 'Noto Sans';

@font-face {
  font-family: 'Roboto Condensed';
  src: url('../static/RobotoCondensed-Regular.ttf') format('truetype');
  font-display: block;
}
@font-face {
  font-family: 'Noto Sans';
  src: url('../static/NotoSans-Regular.ttf') format('truetype');
  font-display: swap;
}
