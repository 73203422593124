@import '~minireset.css/minireset';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import './fontawesome.min.css';
@import './util.scss';
@import './header.scss';
@import './sections/services.scss';
@import './sections/references.scss';
@import './sections/intro.scss';
@import './sections/roof.scss';
@import './sections/jobs.scss';
@import './sections/surface.scss';
@import './sections/renovation.scss';
@import './sections/error-page.scss';
@import './sections/contact.scss';
@import './sections/hero-slider.scss';
@import './footer.scss';

html,
body {
  width: 100%;
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 10px;
}
* {
  box-sizing: border-box;
}

body {
  color: black;
  background-color: $bg-body;
  // display: flex;
  // flex-direction: column;
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: grey;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s;
  }

  &.inactive::before {
    opacity: 0.8;
  }
}

main {
  text-align: center;
  width: 100%;
  margin-top: 70px;
  z-index: 1;
}

section {
  padding-bottom: 50px;
  padding-top: 60px;
  margin: 0 auto;
  h2 {
    padding-top: 20px;
    font-weight: 700;
    font-size: 3.5rem;
    line-height: 4rem;
    color: $blue;
  }
  h3 {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2.8rem;
  }
  h4 {
    font-size: 2rem;
    line-height: 2.4rem;
  }
  p,
  li,
  a {
    font-size: 1.6rem;
    line-height: 1.8rem;
  }
}

#intro {
  width: 100%;
}

@media screen and (min-width: 1200px) {
  body::before {
    content: none;
  }

  section {
    max-width: 1170px;
    margin-left: auto;
    margin-right: auto;
    h2 {
      font-size: 5rem;
    }
  }
}
