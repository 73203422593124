section#Fassade {
    padding: 20px;
    
    h2 {
        padding-bottom: 25px;
    }
    p {
        text-align: left;
    }
    p,li {
        line-height: 2.6rem;
    }
    & > ul {
        text-align: left;
        padding-left: 20px;
        padding-top: 20px;
        & > li::before {
          font-family: 'FontAwesome';
          content: '\f058';
          padding-right: 10px;
        }
    }
}

@media screen and (min-width: 1200px) {
    section#Fassade {
        p,li {
            font-size: 20px;
            line-height: 24px;
            padding-bottom: 20px;
        }
    }
}