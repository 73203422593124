@import '../util.scss';

section#Leistungen {
  background-color: #f7f7f7;
  padding: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  .services {
    .service {
      margin-bottom: 40px;
      i {
        font-size: 2.4rem;
        line-height: 2.4rem;
      }
      h4 {
        margin-top: 10px;
      }
      ul {
        list-style: none;
        text-align: left;
        display: inline-block;
        li {
          font-size: 1.8rem;
          line-height: 19.8px;
          font-weight: 500;
          margin: 10px 0 10px 0;
          &::before {
            font-family: FontAwesome;
            content: '\f058';
            padding-right: 10px;
          }
        }
      }
      hr {
        width: 50%;
        margin: 0 auto;
      }
    }
  }
  p {
    color: #d9534f;
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.4rem;
    margin-bottom: 20px;
  }
  a {
    text-decoration: none;
    color: white;
    background: #d9534f;
    font-size: 2rem;
    line-height: 2.2rem;
    padding: 15px;
    font-weight: 700;
    border-radius: 5px;
    text-align: center;
    display: inline-block;

    &:active,
    &:hover {
      background: #d2322d;
    }
  }

  h3 {
    font-size: 2.4rem;
    line-height: 2.8rem;
    margin-bottom: 40px;
  }
}
@media screen and (min-width: 768px) {
  section#Leistungen {
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    .services {
      display: flex;
      .service {
        flex-basis: 25%;
        hr {
          width: 90%;
        }
        &:nth-child(1) {
          margin-left: 50px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) {
  section#Leistungen {
    padding-left: calc((100vw - 1170px) / 2);
    padding-right: calc((100vw - 1170px) / 2);
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    min-height: 620px; //remove
  }
}
